.FundraiserCta {
    @apply  my-36;
    
    &-container {
        @apply  mx-auto; 

        @screen md {
            @apply  w-10/12;
        }
    }
}

.FundraiserServices {
    @extend .HomeMakePrintingEasy;
}


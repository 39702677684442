body, html {

    &.mobile-nav-is-active {
        @apply  overflow-y-hidden;
    }

}

.container {
    @apply  mx-auto
            w-[95%];
            max-width: $max-width;
}

.SecondaryNav {
    a {
        @apply  text-white
                text-xs
                uppercase
                font-bold;

        letter-spacing: 0.045em;

        &:first-child {
            @apply  mr-[3.125rem];
        }
    }
}

.MainNav {
    @apply  my-[18px];

    .Logo {
        @apply  pl-[2.5%];

        @screen lg {
            @apply  pl-0;
        }
    }

    .MainNav-burger {
        @apply  mr-[2.5%];

        @screen lg {
            @apply  mr-0;
        }
    }

    &-container {
        @apply  w-full 
                flex 
                items-center 
                justify-between 
                relative;

        @screen lg {
            @apply  w-[95%];
        }
    }

    .MainNavMenu {
        @apply  absolute
                flex
                flex-col
                items-start
                w-full
                h-screen
                bg-white
                top-[75px]
                pointer-events-none
                transition-all
                z-[-50]
                px-[2.5%];
        left: -110%;

        @screen lg {
            @apply  flex-row 
                    relative
                    w-full
                    self-end
                    h-[unset]
                    left-[unset]
                    top-[unset]
                    px-[unset]
                    pointer-events-auto
                    items-center 
                    justify-end
                    z-auto;
        }

        &-main {
            @apply  flex
                    flex-col
                    items-start
                    w-full;
            > li {
                @apply  w-full
                        py-3;

                div {
                    > a {
                        @apply  text-lg;
                    }

                    &.has-children {
                        @apply  flex
                                flex-wrap
                                justify-between; 
                                
                        &:after {
                            @apply  h-6;
                            content: url($dist + 'images/arrow-down.svg');
                        }
                    }
                }
            }


            @screen lg {
                @apply  flex-row
                        items-center 
                        justify-center
                        w-auto;

                > li {
                    @apply  mr-10
                            w-auto;

                    &:last-child {
                        @apply  mr-0;
                    }

                    > div {
                        &.has-children {
                            @apply  relative
                                    items-center;
                        }

                        > a {
                            @apply  text-[13px]
                                    text-black
                                    font-bold
                                    uppercase
                                    py-2;
                            letter-spacing: 0.045em;
                        }
                    }

                }
            }


            @screen xl {
                > li {
                    @apply  mr-10;
                }
            }
        }
        

        &.is-active {
            @apply  left-0
                    pointer-events-auto
                    z-50;

            .MainNavMenu {
                &-sub {
                    &.is-active {
                        @apply  h-full
                                opacity-100
                                pointer-events-auto
                                p-5;

                        li {
                            @apply  py-5;
                        }
                    }
                }
            }
        }

        &-sub {
            @apply  h-0
                    w-full
                    opacity-0
                    pointer-events-none
                    transition-all
                    order-3;

            > ul {
                @apply  w-full;

                li {
                    @apply  flex 
                            w-full;

                    a {
                       @apply  w-full 
                    }
                }

                @screen lg {
                    li {
                        @apply  pt-6;

                        &:first-child {
                            @apply  pt-0;
                        }

                        > a {
                            @apply  transition-colors
                                    text-[15px];

                            &:hover {
                                @apply  text-blue
                                        font-bold;
                            }
                        }
                    }
                }
            }
            
            @screen lg {
                @apply  absolute
                        top-9
                        left-0
                        w-max
                        opacity-0
                        pointer-events-none
                        z-[-10]
                        transition-all
                        translate-x-10
                        bg-white
                        rounded-xl;
                min-width: 250px;
                height: unset !important;
                box-shadow: 0px 16px 48px rgba(0, 0, 0, 0.16);

                &.is-active {
                    @apply  opacity-100
                            z-50
                            h-full
                            translate-x-0
                            pointer-events-auto
                            p-8;
                }
            }
        }

    }

    .DesignLabBtn {

        @apply  flex 
                items-start 
                justify-start 
                mt-8
                uppercase 
                text-white;

        @screen lg {
            
        }

        @screen xl {
            @apply  justify-center;
        }

        &-btn {
            @apply  text-[0.8125rem];

            letter-spacing: 0.045em;

            @screen lg {
                @apply  ml-5;
            }
        }
    }

    &-burger {
        @apply  p-0
                border-0
                relative
                outline-none
                block;
        z-index: 101;

        width: 40px;

        > span {
            @apply  block
                    relative;
            content: ' ';
            width: 100%;
            height: 3px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 3px;
            transition: all 250ms ease-in-out;

            &::before/* , &::after  */{
                content: ' ';
                height: 100%;

                transition: all 250ms ease-in-out;
            }

            &::before {
                @apply  absolute
                        bg-black
                        w-full
                        rounded-md;
                        
                top: 0;
                left: 0;
            }

            /* &::after {
                @apply  absolute
                        bg-black;
                border-top-right-radius: $rounded-md;
                border-bottom-right-radius: $rounded-md;
                top: 0;
                left: 50%;
                width: 50%;
            } */

            &:first-child, &:last-child {
                width: 80%;
            }

            &:last-child {
                margin-bottom: 0px;
            }
        }

        &.is-open{

            > span {
                &::before {
                    @apply  bg-blue;
                    width: 100%;
                    border-radius: $rounded-md;
                }

                &::after {
                    opacity: 0;
                }

                &:first-child, &:last-child {
                    width: 100%;
                }
            }

        }

        &:focus{
            @apply  outline-none;

            &::before,
            &::after,
            span::before,
            span::after{
                @apply  border-gray;
            }
        }

        @screen lg {
            @apply  hidden;
        }
    }
}

.Footer {
    @apply  pb-8;
}

.FooterNavMain, .FooterNavSecondary {
    @apply  px-3;

    @screen lg {
        @apply  px-8;
    }
}

.FooterNavMain {
    @apply  flex 
            flex-col
            items-start;

    @screen lg {
        @apply  flex-wrap
                flex-row
                justify-between;
    }

    &-bar {
        @apply  hidden;

        @screen lg {
            @apply  block
                    px-6
                    pt-20 
                    w-full;

            &:before {
                @apply  block
                        content-['']
                        h-[1px]
                        bg-gray;
            }
        }
    }
}

.FooterNav {
    @apply  flex 
            flex-col
            justify-between;

    @screen lg {
        @apply  flex-wrap
                flex-row;
    }

    > li {
        @apply  py-8
                border-t
                border-gray-dark;

        &:last-child {
            @apply  border-b
                    border-gray-dark
                    pb-8;
        }

        @screen lg {
            @apply  py-[unset]
                    border-none;
        }
    }

    &-sub {
        @apply  mt-10;

        @screen lg {
            @apply  mt-4;

            li {
                @apply  mb-2;
            }
        }
    }
}

.FooterSubscribe {
    @apply  pt-8;

    &-field {
        &--email {
            @apply  relative
                    flex
                    w-full;

            &::before {
                @apply  h-6
                        mr-5;
                content: url($dist + 'images/email.svg');
            }

            > input[type=email] {
                @apply  bg-blue-lightest
                        w-full;

                @screen lg {
                    background-color: transparent;
                }
            }
        }
    }

    &-submit {
        @apply  uppercase 
                hidden;

        @screen lg {
            @apply  block;
        }


        &--mobile {
            @apply  uppercase 
                    block
                    rounded-full
                    px-2
                    py-1;

            &::before {
                @apply  w-[14px]
                        h-[10px];
                content: url($dist + 'images/arrow-right-white.svg');
            }

            @screen lg {
                @apply  hidden;
            }
        }
    }
    
    &-container {
        @apply  flex
                py-4
                px-5
                border-blue
                border-2
                bg-blue-lightest
                rounded-full;

        @screen lg {
            @apply  border-none
                    p-[unset];
            background-color: transparent;
        }
    }

    @screen lg {
        &-field {
            @apply  mr-[30px];
    
            &--email {
                @apply  relative
                        flex
                        justify-center
                        py-4
                        px-5
                        rounded-full
                        bg-none
                        border-gray
                        border;
    
                input {
                    @apply  w-11/12;
                }
    
                &::before {
                    @apply  h-6
                            mr-5;
                    content: url($dist + 'images/email.svg');
                }
            }
        }
    }
}

.FooterNavSecondary {
    @apply  py-10 
            flex 
            flex-col
            items-center;

    @screen lg {
        @apply  flex-wrap
                flex-row
                justify-between;
    }

    &-copyright {
        @apply  w-full 
                flex 
                flex-wrap 
                items-center 
                justify-center  
                mb-5;

        > p {
            @apply  w-full 
                    text-center 
                    text-black;

            @screen lg {
                @apply  w-auto
                        text-left
                        mr-1;
            }
        }

        @screen lg {
            @apply  w-auto
                    flex-nowrap 
                    items-center 
                    mb-0
                    px-6;
        }
    }

    &-menuContainer {
        @apply  w-4/12
                mx-auto
                flex 
                items-center 
                justify-center;

        @screen lg {
            @apply  justify-end;
        }
    }
    
    &-menu {
        @apply  flex;

        li {
            @apply  mr-8;

            &:last-child {
                @apply  mr-0;
            }
        }
    }
}
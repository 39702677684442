body {
    @apply  font-poppins;
}

h1, h2, h3, h4, h5, h6 {
}

h1 { 
    @apply  text-[80px]
            font-bold;
    line-height: 125%;
    letter-spacing: -0.04em;
}

h2 { 
    @apply  text-[40px]
            font-bold;
}

h3 { 
    @apply  text-2xl
            font-bold;
}

h4 { 
    @apply  text-xl;
}

h5 { 
    @apply  font-bold
            text-lg; 
}

h6 { 
    @apply  text-[22px]
            font-semibold;
}


p {
    @apply  text-base
            font-poppins
            text-gray-darker
            leading-[180%];

    & ~ p {
        @apply  mb-[30px];

        &:last-child {
            @apply  mb-0;
        }
    }
    
    &.large {
        @apply  text-lg;
    }

    &.larger {
        @apply  text-2xl
                leading-[40px];
    }
}

p.larger ~ p.larger {
    @apply  mt-8;
}

small, .small {
    @apply  text-xs;
}

.prose {
    h3 {
        @apply  mb-8;
    }
    
    figure {
        @apply  w-full
                h-auto
                py-14;

        figcaption {
            @apply  w-full
                    text-center
                    pt-3;
        }
    }
}
.WojoForm {
    @apply  grid 
            auto-rows-max 
            gap-y-5;

    &-row {
        @apply  grid
                gap-5
                grid-cols-1
                grid-flow-row;     

        @screen md {
            @apply  auto-cols-fr
                    grid-flow-col;
        }
    }

    &-field {
        @apply  flex
                flex-col
                w-full;

        label, input, select, textarea {
            @apply  font-poppins;
        }

        label {
            @apply  w-auto
                    text-black
                    text-opacity-70
                    text-base
                    leading-[190%];
        }

        input, select, textarea {
            @apply  w-full
                    border-gray
                    border-2
                    p-5
                    rounded-full;

            &:focus {
                @apply  outline-none;

                box-shadow: 0px 0px 2px #0066ff;
            }
        }

        textarea {
            @apply  rounded-[32px];
        }

        select {
            @apply  appearance-none
                    bg-no-repeat;

            background-image:  url($dist + 'images/down-arrow.svg');
            background-position: right 25px top 50%;
        }
    }

    &-warning {
        @apply  text-black 
                text-opacity-70
                my-8;
    }
}
.ServicesListings {
    @apply  mt-14
            mb-20;

    @screen md {
        @apply  mt-28
                mb-64;
    }

    &-container {
        
    }
}

.ServiceListing {

    @apply  mb-8
            flex
            flex-col;

    &:last-child {
        @apply  mb-0;
    }

    @screen md {
        @apply  mb-64
                flex-row;
    }

    &-image, &-text {
        @apply  w-full;

        @screen md {
            @apply  w-1/2;
        }
    }

    &-image {
        @apply  max-h-96
                overflow-hidden;

        img {
            @apply  w-full
                    h-auto;
        }
    }

    &-text {
        &--title {
            @apply  mb-6;
        }
    }

    @screen md {
        &:nth-child(even) {
            .ServiceListing-image {
                @apply  order-2
                        -mr-28;
            } 
            .ServiceListing-text {
                @apply  order-1
                        mr-28;
            }
        }

        &:nth-child(odd) {
            .ServiceListing-image {
                @apply  -ml-28
                        mr-0;
            }

            .ServiceListing-text {
                @apply  ml-28
                        mr-0;
            }
        }
    }
}

.ServiceDetail {
    @apply  mt-8
            mb-8;

    @screen md {
        @apply  mt-24
                mb-24;
    }

    &-textBlock {
        @apply  mx-auto
                w-full
                mt-8;

        @screen md {
            @apply  w-8/12
                    mt-24;
        }

        p:first-child {
            @apply  text-center
                    mb-14;
            @extend .larger;
        }
    }

    &-bulletPoints {
        @apply  flex
                flex-col
                items-start
                justify-around
                mt-8;


        @screen md {
            @apply  flex-row
                    mt-24;
        }
        
        li {
            @apply  flex 
                    flex-col
                    w-full
                    items-center
                    justify-center
                    px-5
                    mb-8;

            @screen md {
                @apply  w-1/4
                        mb-0;
            }

            > img {
                @apply  w-auto
                        h-full
                        max-h-[180px]
                        m-auto;
            }

            > h2 {
                @apply  text-2xl
                        text-center
                        mt-9
                        mb-4;
            }

            > p {
                @apply  text-center;
            }
        }

        &--grid {
            @apply  flex-wrap
                    w-8/12
                    mx-auto;

            @screen md {
                @apply  w-6/12;
            }

            li {
                @apply  w-1/2
                        mb-16;
            }
        }

        &--hasTitle, &--blue {
            @apply  py-12;

            .ServiceDetail-bulletPoints {
                @apply  mt-0
                        py-8;
            }
        }

        &--blue {
            @apply  bg-blue
                    mt-8;


            h2, h4, p {
                @apply  text-white;
            }

            h2, h4 {
                @apply  text-center;
            }
        }
    }

    &-fullWidthImage {
        @apply  w-full 
                max-h-[22rem]
                mt-8  
                overflow-hidden;

        @screen md {
            @apply  mt-24;
        }

    }

    &-croppedImage {
        @apply  w-full
                max-h-[22rem]
                mt-8
                overflow-hidden
                flex
                justify-between
                border-l-0
                border-r-0
                border-t-2
                border-b-2
                border-black;
             
        @screen md {
            @apply  mt-24;
        }   

        &--text {
            @apply  w-full
                    flex
                    items-center
                    justify-center
                    text-center
                    py-10;

            @screen md {
                @apply  w-8/12
                        py-0;
            }
        }

        &--image {
            @apply  hidden
                    h-auto
                    relative;


            @screen md {
                @apply  w-4/12
                        block;
            }

            img {
                @apply  relative
                        max-h-full
                        right-0
                        w-full
                        h-auto;

                z-index: 5;
                clip-path: circle(93.0% at 96% 50%);
            }

            &:after {
                @apply  w-full
                        h-full
                        bg-black
                        absolute
                        top-0
                        right-0;

                content: ' ';
                z-index: 1;
                
                clip-path: circle(93% at 95.5% 50%);

                // transform: translateX(-10%);
            }
        }
    }

    &-fullWidthImageText {
        @apply  flex
                flex-col
                relative;

        @screen md {
            @apply  mt-24
                    justify-between;
        }

        &--image {
            @apply  w-full
                    h-auto
                    relative
                    bg-cover
                    bg-center;


            img {
                @apply  w-full
                        h-auto;
            }

            @screen lg {
                @apply  w-4/12
                        h-full
                        absolute
                        top-0 
                        left-0;
            }

            @screen xl {
                // @apply  w-8/12;

                img {
                    @apply  h-full
                            w-auto;
                }
            }
        }

        &--text {
            @apply  w-full
                    flex
                    flex-col
                    items-center
                    my-16;

            @screen md {
                @apply  flex-row;
            }

            @screen lg {
                @apply  w-3/5;
            }

            @screen xl {
                @apply  w-8/12;
            }

            > h1 {
                @apply  mb-6;
            }

            > p.larger {
                @apply  pr-9;
            }
        }

        // counter-reset: servicesList;         

        &--services {
            @apply  w-full  
                    pr-0
                    p-8;
                    
            list-style: none;

            @screen md {
                @apply  w-2/5
                        pr-8
                        p-0;
            }

            li {
                @apply  grid
                        mb-4;

                grid-template-columns: 32px 1fr;


                &:last-child {
                    @apply  mb-0;
                }
            }
        }

        &--counter {

            @apply  block
                    flex
                    justify-center
                    items-center
                    w-[32px]
                    h-[32px]
                    text-center
                    text-black
                    relative
                    bg-contain
                    bg-center
                    bg-no-repeat;

            background-image: url($dist + 'images/icon-hexagon.svg');

        }
    
    }

    &-lrText, &-lrCta {
        @apply  mt-8;

        @screen md {
            @apply  mt-24;
        }

        &--container {
            @apply  flex
                    flex-col
                    items-center
                    justify-between;

            @screen md {
                @apply  flex-row;
            }            
        }

        &--title {
            @apply  w-full
                    pr-5
                    text-left
                    text-7xl
                    leading-[80px];

            @screen md {
                @apply  w-5/12;
            }
        }

        &--text {
            @apply  w-full;

            @screen md {
                @apply  w-6/12;
            }
        }

        &--cta {
            @apply  flex
                    items-center
                    justify-center
                    w-full;

            @screen md {
                @apply  w-5/12;
            }
        }

        &--ctaTitle {
            @apply  w-full
                    text-left
                    mb-3;
        }


        @screen md {
            &--right {
                .ServiceDetail-lrText {
                    &--title {
                        @apply  order-2
                                pl-5
                                text-right;
                    }

                    &--text {
                        @apply  order-1;
                    }
                }

                .ServiceDetail-lrCta {
                    &--cta {
                        @apply  order-2
                                pl-5
                                text-right;
                    }

                    &--text {
                        @apply  order-1;
                    }
                }
            }
        }
    }

    &-lrImageText {
        @apply  mt-8;

        @screen md {
            @apply  mt-24;
        }

        &--container {
            @apply  flex
                    flex-col
                    items-center;

            @screen md {
                @apply  flex-row;
            }
        }

        &--image, &--text {
            @apply  w-full;

            @screen md {
                @apply  w-1/2;
            }
        }

        &--image {
            @screen md {
                @apply  -ml-28;
            }
        }

        &--text {
            h1 {
                @apply  mb-6;
            }

            @screen md {
                @apply  ml-28;

                h1 {
                    @apply  text-5xl
                            leading-[128%];
                }
            }
        }

        @screen md {
            &--right {
                .ServiceDetail-lrImageText {
                    &--image {
                        @apply  order-2
                                ml-0
                                -mr-28;
                    }
    
                    &--text {
                        @apply  order-1
                                mr-28
                                ml-0;
                    }
                }
            }
        }
    }
}



.BlogListings {

    &-serviceDetails {
        
        .BlogListings-container {
            @apply  flex
                    flex-col;
                    
            @screen md {
                @apply  grid
                        max-h-[480px]
                        h-full;

                grid-auto-rows: 1fr;
            }
        }
        
        .BlogListing {
            @apply  h-full
                    mb-5;

            @screen md {
                @apply  mb-0;
            }
    
            &-image {
                @apply  h-full
                        border-l-2 
                        border-r-2 
                        border-t-2 
                        border-blue 
                        rounded-t-xl;
    
                &--portrait {
                    @apply  flex
                            justify-center
                            items-center
                            pt-14;

                    @screen md {
                        @apply  pt-0;
                    }
    
                    img {
                        @apply  max-h-[126px]
                                max-w-[126px]
                                rounded-full
                                border-2
                                border-blue;
                    }
                }
            }
    
            &-text {
                @apply  px-4 
                        border-l-2 
                        border-r-2 
                        border-b-2 
                        border-blue 
                        rounded-b-xl;

                @screen md {
                    @apply  max-h-[270px]
                            h-full;
                }
    
                &--title {
                    @apply  pr-0;
                }
    
                ul {
                    list-style: none;
                    
                    li {
                        @apply  flex
                                items-center;
    
                        &:before {
                            @apply  w-4
                                    h-4
                                    mr-2;
    
                            content: url($dist + 'images/icon-checkmark.svg');
                            
                        }
                    }
                }
            }
        }
    }
}
/**
 * This injects Tailwind's base styles, which is a combination of
 * Normalize.css and some additional base styles.
 */
 @tailwind base;
 /**
  * This injects any component classes registered by Tailwind plugins.
  */
 @tailwind components;
 
 /**
  * This injects all of Tailwind's utility classes, generated based on your
  * config file.
  */
 @tailwind utilities;

// Externals
@import "~flickity/css/flickity.css";
 
// Library
@import "_lib/variables";
@import "_lib/fonts";
@import "_lib/typography";
@import "_lib/buttons";
@import "_lib/components";
@import "_lib/forms";

// Layout
@import "_lib/layout";

// Pages
@import "_pages/home" ;
@import "_pages/blog" ;
@import "_pages/services";
@import "_pages/fundraising";
.btn-wojo {
    @apply  uppercase
          text-white 
            font-bold
            rounded-full 
            py-4 
            px-6
            block
            w-max;
            
    background: $orange-gradient;
}
.HomeHeader {
    @apply  w-full
            overflow-hidden
            relative
            pt-14;

    @screen lg {
        @apply  min-h-[580px]
                py-[52px];
    }

    &-text, &-cta {
        @apply  w-full;

        @screen lg {
            @apply  w-1/2;
        }
    }

    &-cta {
        @screen lg {
            @apply  absolute
                right-0
                top-0;
        }
    }

    &-text {
        @apply  flex
                flex-col
                items-start
                pb-6;

        @screen lg {
            @apply  pb-0;
        }

        h1 {
            @apply  leading-[100px]
                    mb-6;
        }

        &--list {
            @apply  flex
                    items-start
                    mt-6
                    mb-16;

            li {
                @apply  flex
                        justify-start
                        mr-[18px];

                &:last-child {
                    @apply  mr-0;
                }

                &:before {
                    @apply  w-[18px]
                            h-[18px]
                            mr-3;
                    content: url($dist + 'images/checkmark.svg');
                }

                strong {
                    @apply  uppercase
                            text-[15px]
                            leading-5;
                }
            }
        }
        
    }

    .HomeHeaderSlideshow {
        @apply  relative
                w-full
                h-auto;

        .flickity-viewport, .flickity-slider {
            @apply  flex
                    relative;
        }


        /* .flickity-prev-next-button {
            top: unset;
            bottom: 10px;
            transform: none;

            &.previous {
                left: auto;
                right: 60px;
            }

            &.next {
                right: 10px;
            }
        } */

        &-slide {
            @apply  w-full
                    h-[580px];

            &--container {
                @apply  relative 
                        h-full 
                        w-full 
                        flex 
                        items-center 
                        justify-center;
            }
        }

        &-btn {
            @apply  w-auto
                    bg-white
                    p-[6px]
                    rounded-full;
            filter: drop-shadow(14px 13px 20px rgba(0, 139, 208, 0.05));
            box-shadow: 14px 10px 30px rgba(49, 89, 211, 0.12);

            &--icon {
                @apply  mr-4;
            }

            &--text {
                @apply  pr-7;
            }
        }

        &-image {
            @apply  absolute
                    right-0
                    top-1/2
                    h-auto
                    w-full
                    -translate-y-1/2
                    z-[-5];
        }

        &-nav {
            @apply  absolute 
                    right-0 
                    bottom-0 
                    flex
                    items-start
                    z-50 
                    bg-white 
                    rounded-tl-xl 
                    rounded-tr-none
                    rounded-b-none;
                    
            &--previous, &--next {
                @apply  p-6;
            }

            &--previous {
                @apply  pr-[10px];

                > img {
                    transform: scaleX(-1);
                }
            }

            &--next {
                @apply  pl-[10px];
            }
        }
    }
}

.HomeDelivery {
    @apply  w-full
            bg-blue-lightest
            py-14;

    &-icons {
        @apply  flex
                flex-col
                items-center
                justify-center;
        
        @screen md {
            @apply  w-6/12
                    mx-auto
                    flex-row
                    items-start
                    justify-between;
        }
    }

    &-shipping {
        @apply  mb-5;

        @screen lg {
            @apply  mb-0;
        }
    }

    &-shipping, &-delivery {
        @apply  flex
                flex-col
                items-center
                justify-center;
        
        @screen md {
            @apply  flex-row
                    items-start;
        }

        > div {
            @apply  flex
                    flex-col
                    ml-0;

            h3 {
                @apply  mb-2;

                @screen md {
                    @apply  mb-3;
                }
            }

            @screen md {
                @apply  ml-10;
            }
        }
    }
}

.HomePrintSpecialists {
    @apply  flex
            flex-col
            relative;

    @screen md {
        @apply  mt-24
                justify-between;
    }

    &-image {
        @apply  w-full
                h-auto
                relative;

        img {
            @apply  w-full
                    h-auto;
        }

        @screen lg {
            @apply  w-4/12
                    h-full
                    absolute
                    top-0 
                    left-0;
        }

        @screen xl {
            @apply  w-6/12;

            img {
                @apply  h-full
                        w-auto;
            }
        }
    }

    &-text {
        @apply  w-full
                flex
                flex-col
                items-start
                mt-16;

        @screen lg {
            @apply  w-3/5;
        }

        @screen xl {
            @apply  w-7/12;
        }

        > h1, > p.larger {
            @apply  mb-6;
        }

        > p.larger {
            @apply  pr-9;
        }
    }

    &-services {
        @apply  grid
                grid-cols-2
                gap-[18px]
                row-auto
                mt-16;

        li {
            @apply  border-2
                    border-gray
                    bg-white
                    transition-all
                    py-3
                    px-8
                    rounded-xl
                    cursor-pointer;

            &.is-active {
                @apply  border-blue
                        border
                        bg-blue-lightest;
            }
        }
    }
}

.HomeShopProducts {  
    &-mobileBtn {
        @apply  flex 
                items-center 
                justify-center;
        
        @screen lg {
            @apply  hidden;
        }

        > a.btn {
            @apply  block 
                    px-[72px] 
                    py-5;

            @screen lg {
                @apply  hidden;
            }
        }
    }
}

.HomeShopProductsSlider {

    @apply  mt-10 
            ml-5;

    @screen lg {
        @apply  mt-20
                ml-0;
    }

    .flickity-viewport, .flickity-slider {
        @apply  flex
                relative;
    }

    &-product {
        @apply  w-7/12
                h-auto
                border 
                border-gray 
                rounded-xl 
                px-8 
                pb-14 
                mt-16 
                mr-4;
                // h-auto
                // h-[488px]
        @screen lg {
            @apply  w-1/4
                    mr-7;
        }
                
        > a {
            @apply  h-full;

            > img {
                @apply  relative 
                        mx-auto 
                        max-w-[348px] 
                        -mt-16;
            }
        }
    }

    &-nav {
        &--previous {
            > img {
                transform: scaleX(-1);
            }
        }
    }
}


.HomeMakePrintingEasy {
    @apply  flex
            flex-col
            relative
            my-10;

    @screen md {
        @apply  justify-between
                my-36;
    }

    &-image {
        @apply  w-full
                h-auto
                order-1
                relative;

        img {
            @apply  w-full
                    h-auto;
        }

        @screen lg {
            @apply  w-5/12 
                    h-full
                    absolute 
                    top-0 
                    right-0
                    order-2;

            img {
                @apply  h-full
                        w-auto;
            }
        }
    }
}


.HomeCustomizeWebstore {
    @apply  my-10
            flex 
            flex-col
            relative;

    @screen lg {
        @apply  my-36;
    }

    &-image {
        @apply  w-full 
                h-full
                relative;

        img {
            @apply  h-auto
                    w-full;
        }

        @screen lg {
            @apply  w-5/12
                    absolute
                    top-0 
                    left-0;

            img {
                @apply  h-full
                        w-auto;
            }
        }
    }

    &-text {
        @apply  w-full
                py-10;

        p.larger {
            @apply  mt-6;
        }

        @screen lg {
            @apply  w-7/12;
        }
    }

}

.HomeInstagram {
    @apply  py-36;
}

.HomeInstagramGridContainer {
    @apply  h-full 
            relative 
            overflow-x-scroll 
            overflow-y-hidden 
            block 
            py-5;
}

.HomeInstagramGrid {
    @apply  h-full;

    @screen lg {
        @apply  min-h-[900px];
    }

    .flickity-viewport, .flickity-slider {
        @apply  transition-all
                ease-linear;
    }

    &-itemContainer {
        @screen lg {
            @apply  relative 
                    mx-auto;
        }
    }

    &-item {
        @apply  w-full
                overflow-hidden
                transition-all;

        // max-height: 330px;

        img {
            @apply  w-[80%]
                    m-auto
                    h-auto;
        }

        @screen lg {
            @apply  mr-16
                    mb-2
                    overflow-visible
                    w-[unset];

            img {
                @apply  rounded-2xl
                        w-full;
            }
        }
    }

    &-caption {
        @apply  hidden;

        @screen lg {
            @apply  bg-white 
                    transition-all
                    rounded-full
                    p-1
                    z-50
                    flex;

            box-shadow: 0px 19.4647px 15.5717px rgba(0, 0, 0, 0.0178832), 0px 10.9117px 8.72938px rgba(0, 0, 0, 0.015), 0px 5.79514px 4.63611px rgba(0, 0, 0, 0.0121168), 0px 2.41149px 1.92919px rgba(0, 0, 0, 0.00843437);

            &--text {
                @apply  opacity-0
                        hidden;
            }


            &.is-active {
                @apply  pl-5
                        pr-24 
                        py-6
                        rounded-2xl;

                .HomeInstagramGrid-caption {
                    &--image {
                        @apply  mr-2;
                    }

                    &--text {
                        @apply  opacity-100
                                block;
                    }
                }
            }
        }

    }

    &-nav {
        &--previous {
            > img {
                transform: scaleX(-1);
            }
        }
    }
}

.HomeComments {
    @apply  w-full 
            h-full
            mt-32;

    @screen lg {
        @apply  flex 
                flex-nowrap 
                items-start 
                flex-shrink-0 
                overflow-x-scroll 
                overflow-y-hidden;
    }

    &-item {
        @apply  w-10/12
                h-[250px]
                mx-4
                min-w-max
                min-w-min
                flex 
                self-start 
                items-start 
                justify-between 
                flex-wrap 
                bg-blue-lightest 
                border-blue 
                border 
                py-8 
                px-12 
                rounded-[32px];

        @screen lg {
            @apply  min-w-[500px]
                    mr-4;
        }
    }
}
.BlogListings {
    @apply  mt-28;

    &-container {
        @apply  grid 
                gap-x-[30px]
                grid-cols-1
                auto-rows-auto;   

        @screen md {
            @apply  grid-cols-3;
        }
    }
}

.BlogListing {
    &-image {
        @apply  overflow-hidden 
                w-full 
                max-h-[300px]
                rounded-t-xl;

        img {
            @apply  w-full;
        }

        @screen md {
            @apply  max-h-[240px];
        }
    }

    &-text {
        @apply  px-8 
                pt-8
                pb-11
                border-l-2 
                border-r-2 
                border-t-0 
                border-b-0 
                border-gray;

        &--title {
            @apply  mb-4
                    pr-5;
        }
    }

    &-meta {
        @apply  flex 
                items-center 
                justify-between 
                px-8 
                pb-8
                border-l-2 
                border-r-2 
                border-b-2 
                border-gray 
                rounded-b-xl;
    }

    &-author {
        @apply  flex;

        &--image {
            @apply  w-10 
                    h-10 
                    overflow-hidden 
                    rounded-full;
        }

        &--name {
            @apply  flex 
                    items-center 
                    justify-start
                    ml-3;
        }
    }

    &-postDate {
        @apply  uppercase;
    }
}


.BlogListingsLoad {
    @apply  mt-16 
            mb-36 
            mx-auto;
}

.BlogDetail {

    @apply  mb-14;

    @screen md {
        @apply  mb-36;
    }

    &-container {
        @apply  w-full
                mx-auto;
        
        @screen md {
            @apply  w-8/12;
        }
    }

    &-breadcrumbs {
        @apply  flex 
                py-14;

        li {
            a {
                @apply  text-blue
                        relative;

                &:after {
                    @apply  absolute
                            left-1/2
                            -translate-x-1/2
                            origin-center
                            bg-blue
                            w-full
                            h-[1px]
                            bottom-1
                            content-['']
                            transition-all;
                }

                &:hover {
                    &:after {
                        @apply  w-0;
                    }
                }
            }

            &:after {
                @apply  content-['>']
                        px-[0.32rem];
                        
            }

            &:last-child {
                &:after {
                    @apply  content-none;
                }
            }
        }

        &--active {
            @apply  text-black;
        }
    }

    &-meta {
        @apply  flex 
                items-center 
                justify-between 
                py-6
                mb-16
                border-t
                border-b
                border-gray;
    }

    &-author {
        @apply  flex;

        &--image {
            @apply  w-10 
                    h-10 
                    overflow-hidden 
                    rounded-full;
        }

        &--name {
            @apply  flex 
                    items-center 
                    justify-start
                    ml-3;
        }
    }

    &-postDate {
        @apply  uppercase;
    }

    &-share {
        @apply  w-full
                border-t
                border-b
                py-8
                mt-16
                border-gray
                flex
                items-center; 

        ul {
            @apply  flex
                    mx-auto;

            li {
                @apply  mr-7;

                &:last-child {
                    @apply  mr-0;
                }
            }
        }
    }
}

.fancy {
    @apply  relative
            w-full;

    &::after {
        @apply  absolute
                -bottom-1
                left-0
                w-full
                rounded-xl
                content-[''];

        height: 3px;
        background: $orange-gradient;
    }

    &--shorter {
        &::after {
            @apply  w-4/5
                    left-2/4
                    -translate-x-2/4;
        }
    }

    &--bar {
        &::after {
            @apply  w-full
                    rounded-[9px]
                    bottom-0;

            height: 4px;
        }
    }
}

.ReasonsList {

    @apply  mt-10;

    li {
        @apply  py-5 
                px-8
                mb-4
                flex
                items-center
                justify-start
                cursor-pointer
                relative;
                
        &:before {
            @screen lg {
                @apply  mr-8
                        opacity-20;

                content: url($dist + 'images/bullet.svg');
            }
        }

        &:after {
            @apply  absolute
                    content-['']
                    top-0
                    left-0
                    w-full
                    h-full
                    transition-opacity
                    opacity-0
                    border
                    border-blue
                    z-[-1]
                    rounded-[32px];

            background-color: $blue-lightest;
            
            @screen lg {
                @apply  border-none;

                background-color: transparent;
                background-image: url($dist + 'images/bullet-bg.svg');
                background-repeat: no-repeat;
            }
        }

        &.is-active {
            &:before, &:after {
                @apply  opacity-100;
            }
        }

        > div {
            h5 {
                @apply  font-bold
                        text-2xl;
            }
        }
    }

    &--reverse {
        li {
            @apply  pl-10;
            
            @screen lg {
                @apply  pl-[72px];
            }

            &:after {
                transform: scaleX(-1);
                background-position-x: right;            
            }
        }
    }
}